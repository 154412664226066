.card {
  display: flex;
  flex-direction: column;
  max-width: 354px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  overflow: hidden;
  z-index: 0;
  order: var(--order);
  opacity: 1;
  transition: 0.3s linear;

  @starting-style {
    opacity: 0;
  }
}

.container {
  display: contents;
  background: var(--color-championship-white);
  color: unset;
  text-decoration: none;

  & *:any-link {
    color: unset;
    text-decoration: none;
  }

  @media (--tablet) {
    flex-grow: 1;

    & > a {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
}

.content {
  flex-grow: 1;
  display: grid;
  grid-template-rows: [make-line] min-content [feat-line] min-content [purchase-line] auto;
  padding: var(--spacing-3) var(--spacing-3) 0;
  border: 1px solid var(--color-tarmac-grey-100);
  border-top: 0;
  min-height: 184px;

  &[data-has-call-dealer='true'] {
    grid-template-rows: [make-line] min-content [feat-line] min-content [purchase-line] auto [call-line] 49px;
  }

  &[data-has-guarantee='true'] {
    @media (--tablet) {
      grid-template-rows: [make-line] min-content [feat-line] min-content [purchase-line];
    }
    grid-template-rows: [make-line] min-content [feat-line] min-content [guarantee-line] min-content [purchase-line];
  }

  &[data-has-guarantee='true'][data-has-call-dealer='true'] {
    @media (--tablet) {
      grid-template-rows: [make-line] min-content [feat-line] min-content [purchase-line] auto [call-line] 49px;
    }
    grid-template-rows: [make-line] min-content [feat-line] min-content [guarantee-line] min-content [purchase-line] auto [call-line] 49px;
  }
}

.headerWrapper {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: var(--spacing-2);
  width: 100%;
}

.makeModel {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 var(--spacing-3) 0;
}

.make {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.model {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 14px;
}

.favouritesButton {
  align-self: start;
}

.labels {
  grid-row-start: feat-line;
  display: flex;
  flex-flow: row wrap;
  gap: var(--spacing-2);
  overflow: hidden;
  max-height: 28px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.label {
  border-radius: var(--spacing-1);
  padding: var(--spacing-1) var(--spacing-2);

  &:nth-child(3) {
    text-transform: capitalize;
  }
}

.guaranteeGrid {
  @media (--tablet) {
    display: none;
  }

  grid-row-start: guarantee-line;
  display: grid;
  align-items: center;
  gap: var(--spacing-2);
  margin-top: var(--spacing-3);
  padding-top: 0;
  padding-bottom: 0;
}

.purchaseGrid {
  grid-row-start: purchase-line;
  align-self: end;

  display: grid;
  grid-template-areas: 'price gauge' 'ecom delivery';
  align-items: end;
  gap: var(--spacing-2);
  margin-top: var(--spacing-5);
  margin-bottom: var(--spacing-3);
  grid-template-columns: calc(50% - 8px) calc(50% - 8px);
  &[data-variant='financeFirst'] {
    grid-template-areas: 'ecom gauge' 'price delivery';
  }

  &[data-variant='oneRow'] {
    grid-template-areas: 'price delivery';
  }
}

.price {
  grid-area: price;
}

.ecom {
  grid-area: ecom;
  overflow: visible;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;

  .fincalcLabel {
    display: flex;
    gap: var(--spacing-1);
  }

  && button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: unset;
    margin: 0;
  }

  && svg {
    transform: none;
  }
}

.ecom span button span {
  text-decoration: underline;
  font-size: 14px;
}

.delivery {
  grid-area: delivery;
  justify-self: end;

  & > span {
    display: flex;
    align-items: center;
    gap: var(--spacing-1);
    text-transform: capitalize;
    &[data-variant='has-distance'] {
      text-transform: inherit;
    }
  }
}

.gauge {
  justify-self: end;
  grid-area: gauge;
}

.ctaCont {
  &:has(div) {
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-self: flex-end;
    margin-left: -12px;
    padding: var(--spacing-2) 0;
    height: var(--spacing-12);
    width: calc(100% + 24px);
    border-top: 1px solid var(--color-tarmac-grey-100);

    & > div {
      flex: 1;
    }

    & > :nth-child(2):before {
      content: '';
      position: absolute;
      left: 50%;
      display: block;
      width: 1px;
      height: 24px;
      background-color: var(--color-tarmac-grey-100);
    }
  }
}

.tileFooter {
  background-color: white;
  height: 48px;

  &[data-finance='false'] {
    height: 32px;
    @media (--desktop) {
      height: 48px;
    }
  }

  &:focus-within {
    background-color: white;
  }

  &:active {
    background-color: var(--white);
  }
}

.imgSkltn {
  width: 100%;
  aspect-ratio: 4/3;
}
